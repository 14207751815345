import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import { handleSchedule } from 'helpers/trainingHelper';
import {
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  TrainingSchedule,
  TrainingContainer,
  TrainingTopic,
  TopicContainer,
} from './trainingCard.styles';
import { TrainingCardProps } from './trainingCard.types';

const TrainingCard = ({ training }: TrainingCardProps) => {
  return (
    <TrainingContainer
      key={training.attributes.Title}
      href={`${PATHS.TRAINING}/${transformToSlug(training.attributes.slug.toLowerCase())}`}
    >
      <ImageContainer>
        <Tag>Training</Tag>
        {CMSParseImage(training.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="training-data">
        <TrainingSchedule>{handleSchedule(training.attributes.Schedule)}</TrainingSchedule>
        <TitleContainer className="training-title">{training.attributes.Title}</TitleContainer>
        <SubtitleContainer>{training.attributes.Subtitle}</SubtitleContainer>
        <TopicContainer>
          <TrainingTopic
            onClick={() =>
              (window.location.href = `${PATHS.TRAINING}/?filter=${
                training.attributes?.Primary_Field || ''
              }`)
            }
          >
            {training.attributes?.Primary_Field || ''}
          </TrainingTopic>
        </TopicContainer>
      </DataContainer>
    </TrainingContainer>
  );
};

export default TrainingCard;
