import React, { SVGProps } from 'react';

const ShareLinkIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" {...props}>
      <circle data-name="Ellipse 253" cx="13" cy="13" r="13" fill="#252a34" />
      <path
        d="m13.287 15.772-2.833 2.832a1.84 1.84 0 0 1-2.555 0 1.8 1.8 0 0 1 0-2.555l2.833-2.832a.733.733 0 0 0-1.037-1.037l-2.833 2.84a3.271 3.271 0 0 0 4.621 4.621l2.84-2.833a.733.733 0 0 0-1.037-1.037Zm6.264-8.819a3.278 3.278 0 0 0-4.621 0l-2.841 2.832a.733.733 0 0 0 1.037 1.037l2.833-2.833a1.84 1.84 0 0 1 2.555 0 1.8 1.8 0 0 1 0 2.555l-2.833 2.833a.733.733 0 1 0 1.037 1.037l2.833-2.84a3.278 3.278 0 0 0 0-4.621Zm-8.659 8.658a.74.74 0 0 0 1.037 0l3.592-3.592a.733.733 0 0 0-1.037-1.037l-3.592 3.593a.73.73 0 0 0 0 1.037Z"
        fill="#fff"
      />
    </svg>
  );
};

export default ShareLinkIcon;
