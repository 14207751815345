import { BlogsProps } from '@sections/BlogList/blogList.types';

export const handleSortedBlogs = (blogs: BlogsProps[]) => {
  const sortedBlogsViaPublicationDate = blogs.sort((blog1, blog2) => {
    const parseDateBlog1 = blog1.attributes.Publication_Date.replace(/(\d+)(st|nd|rd|th)/, '$1');
    const parseDateBlog2 = blog2.attributes.Publication_Date.replace(/(\d+)(st|nd|rd|th)/, '$1');

    return new Date(parseDateBlog1) < new Date(parseDateBlog2) ? 1 : -1;
  });

  return sortedBlogsViaPublicationDate;
};
