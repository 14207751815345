import React, { useEffect, useState } from 'react';
import { handleCopyToClipboard } from 'helpers/resourceCenterHelper';
import {
  Chip,
  FilterChips,
  IconCloseContainer,
  Share,
  ShareContainer,
  ShareTooltip,
} from './styles';
import ShareLinkIcon from './assets/shareLink';
import CloseIcon from './assets/icon-close.svg';
import { ShareLinkProps } from './types';

const ShareLink = ({
  filterApplied,
  searchInput,
  articleHaveResult,
  handleFilterApplied,
  handleConfirmSearchInput,
}: ShareLinkProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderTooltip(false);
    }, 3000);
  }, [renderTooltip]);

  return (
    <ShareContainer>
      <FilterChips>
        {searchInput !== '' && (
          <Chip>
            {searchInput}
            <IconCloseContainer src={CloseIcon} onClick={() => handleConfirmSearchInput('')} />
          </Chip>
        )}
        {filterApplied.map((filter) => (
          <Chip>
            {filter}
            <IconCloseContainer src={CloseIcon} onClick={() => handleFilterApplied(filter)} />
          </Chip>
        ))}
      </FilterChips>
      {articleHaveResult && (
        <Share onClick={() => handleCopyToClipboard(filterApplied, searchInput, setRenderTooltip)}>
          Share filtered results
          <ShareLinkIcon />
          <ShareTooltip renderTooltip={renderTooltip}>Copied Link!</ShareTooltip>
        </Share>
      )}
    </ShareContainer>
  );
};

export default ShareLink;
