import { MOBILE_460 } from '@global/styles/sizes';
import styled from 'styled-components';

export const FilterChips = styled.div`
  box-sizing: border-box;
  div {
    margin: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ShareContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

export const Chip = styled.div`
  min-height: 25px;
  padding: 5px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #0792b1;
  text-decoration: none;
  background-color: #e5f4f7;
`;

export const IconCloseContainer = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Share = styled.div`
  margin-top: 6px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 25px;
  color: #252a34;
  float: left;

  svg {
    width: 26px;
    height: 26px;
  }

  &:hover {
    svg {
      circle {
        fill: #0e8cb9;
      }
    }
  }
`;

export const ShareTooltip = styled.div<{ renderTooltip: boolean }>`
  width: max-content;
  padding: 10px;
  bottom: 90%;
  right: 0;
  visibility: ${({ renderTooltip }) => (renderTooltip ? 'visible' : 'hidden')};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0792b1;
  text-decoration: none;
  background-color: #e5f4f7;

  @media (max-width: ${MOBILE_460}px) {
    right: 0;
  }
`;
