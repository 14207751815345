import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Head from '@components/Head';

import { LayoutContainer } from '@components/Layout';
import { WelcomePromoBar } from '@global/data/promobarsData';
import ResourcesCenterHero from '@sections/ResourcesCenterHero/ResourcesCenterHero';
import { GlobalWrapper } from '@global/styles/grid';
import ArticleList from '@sections/ArticlesList/ArticleList';
import ArticleFilter from '@sections/ArticlesList/components/ArticleFilter/ArticleFilter';
import QuickAccessPanel from '@sections/QuickAccessPanel/QuickAccessPanel';
import { handleFilterViaQueryParam, handleSearchViaQueryParam } from 'helpers/resourceCenterHelper';
import { handleSortedBlogs } from 'helpers/blogHelper';
import { InActionContainer, QuickAccessContainer } from './styles';

const ResourcesCenter = ({ data }: any) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderFilterPage, setRenderFilterPage] = useState(false);
  const [pathfulModuleFilterOpen, setPathfulModuleFilterOpen] = useState<boolean>(false);
  const [topicFilterOpen, setTopicFilterOpen] = useState<boolean>(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState<boolean>(false);
  const [roleFilterOpen, setRoleFilterOpen] = useState<boolean>(false);
  const [clientRender, setClientRender] = useState<boolean>(false);
  const [confirmSearchInput, setConfirmSearchInput] = useState<string>(searchInput || '');

  const landingLayoutData = data?.cms?.landing?.data?.attributes?.Layout;
  const quickAccessPanelData = data?.cms?.inActionQuickPanelAccesses?.data;
  const topicsFilter = data?.cms?.blogTopics?.data;
  const rolesFilter = data?.cms?.blogRoles?.data;
  const typesFilter = data?.cms?.typeFilters?.data;

  const pathfulModuleFilter = data?.cms?.blogCareerClusters?.data;
  const heroData = {
    blogs: data?.cms.heroblogs.data,
    materials: data?.cms.heroMaterials.data,
    trainings: data?.cms.heroTrainings.data,
    caseStudies: data?.cms.heroCaseStudies.data,
  };
  const materials = data?.cms.materials.data;
  const webinars = data?.cms.webinars.data;
  const caseStudies = data?.cms.caseStudies.data;
  const blogs = handleSortedBlogs(data?.cms.blogs.data);

  const handleMaterialsCarrousel = () => {
    if (materials.length > 5) {
      return materials.slice(0, 5);
    }
    return materials;
  };

  const handleFilterApplied = (value: string) => {
    if (filterApplied.find((filter) => filter === value)) {
      setFilterApplied(filterApplied.filter((filter) => filter !== value));
    } else {
      setRenderFilterPage(true);
      setFilterApplied([...filterApplied, value]);
      window.scrollTo(0, 0);
    }
  };

  const handleClearFilters = () => {
    setFilterApplied([]);
  };

  const handleSearch = (query: string) => {
    setSearchInput(query);
    setConfirmSearchInput(query);
    setRenderFilterPage(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setClientRender(true);

    const urlParams = new URLSearchParams(window.location.search);
    handleFilterViaQueryParam(urlParams, setFilterApplied);
    handleSearchViaQueryParam(urlParams, setConfirmSearchInput);

    if (urlParams.get('filter')) {
      setRenderFilterPage(true);
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      <LayoutContainer
        promobarConfig={WelcomePromoBar}
        activeLogo={landingLayoutData?.Topbar_Scroll_Logo}
        topbarBorderColor={landingLayoutData?.Topbar_Details_Color}
        topbarMenuId={landingLayoutData?.Topbar_Menu}
        footerMenuId={landingLayoutData?.Footer_Menu}
        floatingTopbar
      >
        {renderFilterPage ? (
          <GlobalWrapper>
            <ArticleFilter
              caseStudies={caseStudies}
              blogs={blogs}
              topicsFilter={topicsFilter}
              rolesFilter={rolesFilter}
              pathfulModuleFilter={pathfulModuleFilter}
              materials={materials}
              trainings={webinars}
              filterApplied={filterApplied}
              typesFilter={typesFilter}
              handleFilterApplied={handleFilterApplied}
              handleClearFilters={handleClearFilters}
              pathfulModuleFilterOpen={pathfulModuleFilterOpen}
              roleFilterOpen={roleFilterOpen}
              setPathfulModuleFilterOpen={setPathfulModuleFilterOpen}
              setRoleFilterOpen={setRoleFilterOpen}
              setTopicFilterOpen={setTopicFilterOpen}
              setTypeFilterOpen={setTypeFilterOpen}
              topicFilterOpen={topicFilterOpen}
              typeFilterOpen={typeFilterOpen}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              confirmSearchInput={confirmSearchInput}
              setConfirmSearchInput={setConfirmSearchInput}
            />
          </GlobalWrapper>
        ) : (
          clientRender && (
            <>
              <ResourcesCenterHero data={heroData} />
              <GlobalWrapper>
                <InActionContainer>
                  <QuickAccessContainer isResourceCenter isMainAccess>
                    <QuickAccessPanel data={quickAccessPanelData} isResourceCenter />
                  </QuickAccessContainer>
                  <ArticleList
                    caseStudies={caseStudies}
                    blogs={blogs}
                    topicsFilter={topicsFilter}
                    rolesFilter={rolesFilter}
                    pathfulModuleFilter={pathfulModuleFilter}
                    materials={handleMaterialsCarrousel()}
                    trainings={webinars}
                    typesFilter={typesFilter}
                    filterApplied={filterApplied}
                    handleFilterApplied={handleFilterApplied}
                    handleSearch={handleSearch}
                  />
                </InActionContainer>
              </GlobalWrapper>
            </>
          )
        )}
      </LayoutContainer>
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    cms {
      landing(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Details_Color
              Topbar_Scroll_Logo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              Topbar_Menu
              Footer_Menu
            }
          }
        }
      }
      webinars(pagination: { limit: -1 }, sort: "publishedAt:desc") {
        data {
          attributes {
            slug
            Title
            Subtitle
            Schedule
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
            Type_Filters {
              data {
                attributes {
                  Type
                }
              }
            }
            Primary_Field
            publishedAt
          }
        }
      }
      materials(pagination: { limit: -1 }, sort: "publishedAt:desc") {
        data {
          attributes {
            slug
            Title
            Subtitle
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
            Primary_Role {
              data {
                attributes {
                  Role
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
            Type_Filters {
              data {
                attributes {
                  Type
                }
              }
            }
            Tag {
              data {
                attributes {
                  Text_Color
                  Type
                }
              }
            }
            publishedAt
          }
        }
      }
      blogs(
        pagination: { limit: -1 }
        filters: { Hide_Blog: { eq: false } }
        sort: "publishedAt:desc"
      ) {
        data {
          attributes {
            slug
            Title
            Subtitle
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
            Type_Filters {
              data {
                attributes {
                  Type
                }
              }
            }
            publishedAt
          }
        }
      }
      caseStudies(pagination: { limit: -1 }, sort: "publishedAt:desc") {
        data {
          attributes {
            slug
            Title
            Subtitle
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
            Type_Filters {
              data {
                attributes {
                  Type
                }
              }
            }
            Primary_Field
            Secondary_Field
            publishedAt
          }
        }
      }
      heroTrainings: webinars(
        filters: { In_Action_Panel: { eq: true } }
        pagination: { limit: 3 }
        sort: "publishedAt:desc"
      ) {
        data {
          attributes {
            slug
            Title
            Subtitle
            Schedule
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
          }
        }
      }
      heroMaterials: materials(
        filters: { In_Action_Panel: { eq: true } }
        pagination: { limit: 3 }
        sort: "publishedAt:desc"
      ) {
        data {
          attributes {
            slug
            Author
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
            Primary_Role {
              data {
                attributes {
                  Role
                }
              }
            }
            Tag {
              data {
                attributes {
                  Text_Color
                  Type
                }
              }
            }
          }
        }
      }
      heroblogs: blogs(
        filters: { In_Action_Panel: { eq: true }, Hide_Blog: { eq: false } }
        pagination: { limit: 3 }
        sort: "publishedAt:desc"
      ) {
        data {
          attributes {
            slug
            Author
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
          }
        }
      }
      heroCaseStudies: caseStudies(
        filters: { In_Action_Panel: { eq: true } }
        pagination: { limit: 3 }
        sort: "publishedAt:desc"
      ) {
        data {
          attributes {
            slug
            Author
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
            Secondary_Field
          }
        }
      }
      inActionQuickPanelAccesses(pagination: { limit: -1 }) {
        data {
          attributes {
            Quick_Panel_Access_CTA_Link
            Quick_Panel_Access_CTA_Text
            Quick_Panel_Access_Subtitle
            Quick_Panel_Access_Title
            Quick_Panel_Access_Icon {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
      blogCareerClusters {
        data {
          attributes {
            Pathful_Module
          }
        }
      }
      blogRoles {
        data {
          attributes {
            Role
          }
        }
      }
      blogTopics {
        data {
          attributes {
            Topic
          }
        }
      }
      typeFilters {
        data {
          attributes {
            Type
          }
        }
      }
    }
  }
`;
export default ResourcesCenter;
